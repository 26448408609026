<template>
  <div>
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
           <!-- Period -->
            <b-col cols="12" md="2">
                <b-form-group
                    :label="$t('apps.reportLedger.singular.period')"
                    label-for="period"
                >
                    <v-select
                        class="select-size-sm"
                        v-model="form.period"
                        :options="LOV.period"
                        :reduce="field => field.value"
                        @input="handlePeriod"
                    />
                </b-form-group>
            </b-col>
            <!-- Date Start -->
            <b-col cols="12" md="2">
             <b-form-group
                 :label="$t('apps.reportLedger.singular.dateStart')"
                 label-for="date-start"
             >
               <flat-pickr
                   id="date-start"
                   :config="dateFormat"
                   v-model="form.dateStart"
                   class="form-control form-control-sm"
               />
             </b-form-group>
            </b-col>
            <!-- Date End -->
               <b-col cols="12" md="2">
                <b-form-group
                    :label="$t('apps.reportLedger.singular.dateEnd')"
                    label-for="date-start"
                >
                    <flat-pickr
                        id="date-end"
                        :config="dateFormat"
                        v-model="form.dateEnd"
                        class="form-control form-control-sm"
                    />
                </b-form-group>
               </b-col>
            <!-- branch  -->
            <b-col cols="12" md="4">
                <b-form-group
                  :label="$t('globalSingular.agent')"
                  label-for="agent"
                >
                    <v-select
                        class="select-size-sm"
                        v-model="form.branch"
                        :options="LOV.branchLOV"
                        :reduce="field => field.id"
                        label="label"
                    />
                </b-form-group>
            </b-col>
            <!-- no document  -->
            <b-col cols="12" md="2">
              <b-form-group
                label="No Document"
                label-for="noDocument"
              >
                   <b-form-input
                           id="noDocument"
                           size="sm"
                           v-model="form.noDocument"
                           placeholder="No Doc" 
                   />
              </b-form-group>
            </b-col>
            <!-- search -->
            <b-col cols="12" md="3">
                <b-button block variant="primary" size="sm" @click="getDataSearch(0)" class="mt-2 pd-1">
                  {{ $t('globalActions.find') }}
                </b-button>
            </b-col>
        </b-row>
      </b-form>
    </b-card>
    <div class="mt-1 mb-1 d-flex justify-content-end">
      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>
      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">
           <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>
          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>
        </b-dropdown>
      </b-button-group>
    </div>
    <b-card no-body id="pdfDom">
      <!-- judul header  -->
      <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ $t('apps.reportCH.moduleName') }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
        </b-card-sub-title>
      </b-card-body>
      <div v-if="loading" class="my-2 text-center text-primary d-flex flex-column justify-content-center">
            <div class="mb-1 text">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
      </div>
      <!-- table  -->
        <b-table-simple id="ch-checkpoint-table" responsive>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th rowspan="2" class="text-center align-middle">No. </b-th>
              <b-th rowspan="2" class="text-center align-middle">AWB No.</b-th>
              <b-th rowspan="2" class="text-center align-middle">AWB Origin </b-th>
              <b-th rowspan="2" class="text-center align-middle">Product Service</b-th>
              <b-th rowspan="2" class="text-center align-middle">Kilo</b-th>
              <b-th rowspan="2" class="text-center align-middle">KOli</b-th>
              <b-th colspan="4" class="text-center align-middle">Delivery Fee (SD/RB)</b-th>
              <b-th colspan="5" class="text-center align-middle">Forward (LF/SF)</b-th>
              <b-th colspan="4" class="text-center align-middle">Jasa Gudang (IM)</b-th>
              <b-th colspan="4" class="text-center align-middle">Transit (IM)</b-th>
              <b-th colspan="5" class="text-center align-middle">Missing Checkpoint(MC)</b-th>
              <b-th rowspan="2" class="text-center align-middle">Total</b-th>
              <b-th rowspan="2" class="text-center align-middle">Catatan</b-th>
              <b-th rowspan="2" class="text-center align-middle">Catatan Perhitungan Break Down</b-th>
            </b-tr>
            <b-tr>
              <!-- Delivery Fee (SD/RB) -->
              <b-th class="text-center">CP Origin</b-th>
              <b-th class="text-center">CP Dest</b-th>
              <b-th class="text-right">Basic Price</b-th>
              <b-th class="text-right">Subtotal</b-th>
              <!-- Forward (LF/SF) -->
              <b-th class="text-center">CP Origin</b-th>
              <b-th class="text-center">CP Dest</b-th>
              <b-th class="text-right">Basic Price1</b-th>
              <b-th class="text-right">Basic Price 2</b-th>
              <b-th class="text-right">Subtotal</b-th>
              <!-- Jasa Gudang (IM) -->
              <b-th class="text-center">CP Origin</b-th>
              <b-th class="text-center">CP Dest</b-th>
              <b-th class="text-right">APO Basic Charge</b-th>
              <b-th class="text-right">Subtotal</b-th>
              <!-- Transit (IM) -->
              <b-th class="text-center">CP Origin</b-th>
              <b-th class="text-center">CP Dest</b-th>
              <b-th class="text-right">Basic Price</b-th>
              <b-th class="text-right">Subtotal</b-th>
              <!-- Missing Checkpoint(MC) -->
              <b-th class="text-center">CP Origin</b-th>
              <b-th class="text-center">CP Dest</b-th>
              <b-th class="text-center">Origin AWB</b-th>
              <b-th class="text-right">Basic Price</b-th>
              <b-th class="text-right">Subtotal</b-th>

            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(prop) in itemLists" :key="prop.no" id="itemList">
              <b-td class="text-center">{{ prop.no }}</b-td>
              <b-td sticky-column>{{ prop.awb_no }}</b-td>
              <b-td class="text-center">{{ prop.awb_origin }}</b-td>
              <b-td class="text-center">{{ prop.service }}</b-td>
              <b-td class="text-center">{{ prop.kilo }}</b-td>
              <b-td class="text-center">{{ prop.koli }}</b-td>

              <b-td class="text-center">{{ prop.cp_origin_delivery }}</b-td>
              <b-td class="text-center">{{ prop.del_origin }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.basic_price, false) }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.subtotal, false) }}</b-td>

              <b-td class="text-center">{{ prop.cp_origin_forward }}</b-td>
              <b-td class="text-center">{{ prop.cp_dest_forward }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.basic_price1_forward, false) }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.basic_price2_forward, false) }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.subtotal_forward, false) }}</b-td>

              <b-td class="text-center">{{ prop.cp_origin_jasa_gudang }}</b-td>
              <b-td class="text-center">{{ prop.cp_dest_jasa_gudang }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.basic_price_jasa_gudang, false) }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.subtotal_jasa_gudang, false) }}</b-td>

              <b-td class="text-center">{{ prop.cp_origin_transit }}</b-td>
              <b-td class="text-center">{{ prop.cp_dest_transit }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.basic_price_transit, false) }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.subtotal_transit, false) }}</b-td>

              <b-td class="text-center">{{ prop.cp_origin_missing }}</b-td>
              <b-td class="text-center">{{ prop.cp_dest_missing }}</b-td>
              <b-td class="text-center">{{ prop.origin_awb_missing }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.basic_price_missing, false) }}</b-td>
              <b-td class="text-right">{{ formatCurrency(prop.subtotal_missing, false) }}</b-td>

              <b-td class="text-right">{{ formatCurrency( parseFloat(prop.subtotal) + 
                                                          parseFloat(prop.subtotal_forward) +
                                                          parseFloat(prop.subtotal_jasa_gudang) +
                                                          parseFloat(prop.subtotal_transit) +
                                                          parseFloat(prop.subtotal_missing)
                                                          ,false) }}</b-td>
              <b-td class="text-center">{{ prop.catatan }}</b-td>
              <b-td class="text-center">{{ prop.catatan_2 }}</b-td>

            </b-tr>
          </b-tbody>
        </b-table-simple>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">      
      <div>
      <b-button-group>
           <!-- button left page -->
            <b-button
              :disabled="totalPageLeft"
              @click="getDataSearch(-50)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn-icon"
              variant="outline-primary"
            >
              <feather-icon icon="ChevronsLeftIcon" />
            </b-button>
      
      </b-button-group>
     
      <b-button-group>
        <!-- button right page -->
        <b-button
          :disabled="totalPageRight"
          @click="getDataSearch(50)"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-icon"
          variant="outline-primary"
        >
          <feather-icon icon="ChevronsRightIcon" />
        </b-button>
      </b-button-group>
      </div>
    </b-card-body>
   
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import useListTable from '@/comp-functions/useListTable'
import { format, getMonth, startOfMonth, endOfMonth, subMonths, addMonths, subYears, parse } from 'date-fns'
import flatPickr from 'vue-flatpickr-component'
import { formatCurrency } from '@/utils/formatter'
import Ripple from 'vue-ripple-directive'



import {
    BTh,
    BTr,
    BTd,
    BThead,
    BCard,
    BForm,
    BRow,
    BCol,
    BTbody,
    BSpinner,
    BFormGroup,
    BButton,
    BFormInput,
    BButtonGroup,
    BDropdown,
    BCardBody,
    BCardTitle,
    BTableSimple,
    BCardSubTitle,
    BDropdownItemButton
} from 'bootstrap-vue'

export default {
  components: {
    BTh,
    BTr,
    BTd,
    BThead,
    BCard,
    BForm,
    flatPickr,
    BRow,
    BCol,
    BTbody,
    BFormGroup,
    BButton,
    BFormInput,
    vSelect,
    BButtonGroup,
    BDropdown,
    BSpinner,
    BCardBody,
    BCardTitle,
    BTableSimple,
    BCardSubTitle,
    BDropdownItemButton
  },
  directives: {
    Ripple,
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const { $get } = useHttp()
    let loading = ref(false)


    const {
      isBusy,
      itemLists,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })
    
    const form = ref({
        branch: 'JKT',
        noDocument: '',
        period: 'thisMonth',
        dateStart: '',
        dateEnd: ''
    })

    const LOV = ref({
        branchLOV: [],
        period: [
        { value: 'thisMonth', label: 'This Month' },
        { value: 'previousMonth', label: 'Previous Month' },
        { value: 'thisYear', label: 'This Year' },
        { value: 'previousYear', label: 'Previous Year' }
      ]
    })

    const periodReportText = ref()
    const dateFormat = ref({ dateFormat: 'd/m/Y' })
    const tableColumns = [
      { key: 'no', label: 'no', thClass:'', tdClass: '' },
      { key: 'awb_no', label: 'AWB no', thClass:'', tdClass: '' },
      { key: 'awb_origin', label: 'AWB no', thClass:'', tdClass: '' },
      { key: 'service', label: 'Product Service', thClass:'', tdClass: '' },
      { key: 'kilo', label: 'Kilo', thClass:'', tdClass: '' },
      { key: 'koli', label: 'Koli', thClass:'', tdClass: '' },

      { key: 'cp_origin_delivery', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price', label: 'Basic Price', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal', label: 'Subtotal</', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_origin_forward', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest_forward', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price1_forward', label: 'Basic Price1', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price2_forward', label: 'Basic Price', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal_forward', label: 'Subtotal</', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_origin_jasa_gudang', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest_jasa_gudang', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price_jasa_gudang', label: 'APO Basic', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal_jasa_gudang', label: 'Subtotal</', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_origin_transit', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest_transit', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price_transit', label: 'Basic Price', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal_transit', label: 'Subtotal</', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_origin_missing', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest_missing', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'origin_awb_missing', label: 'Origin AWB', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price_missing', label: 'Basic Price', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal_missing', label: 'Subtotal', thClass:'text-right', tdClass: 'text-right' },

      { key: 'total_all', label: 'total', thClass:'', tdClass: '' },
      { key: 'catatan', label: 'catatan', thClass:'', tdClass: '' },
      { key: 'catatan_2', label: 'catatan perhitungan break down', thClass:'', tdClass: '' },

    ]

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      const text = `${formatStartDate} to ${formatEndDate}`
      periodReportText.value = text
      return text
    }

    const handlePeriod = () => {
      const formatString = 'dd/MM/yyyy'
      const thisMonth = new Date()
      const whatMonth = getMonth(thisMonth) + 1
      const previousMonth = subMonths(thisMonth, 1)
      const firstMonthOfYear = subMonths(thisMonth, whatMonth - 1)
      const lastMonthOfYear = addMonths(thisMonth, (whatMonth - 12) * -1)
      const firstMonthOfPreviousYear = subYears(firstMonthOfYear, 1)
      const lastMonthOfPreviousYear = subYears(lastMonthOfYear, 1)

      if (form.value.period === 'thisMonth') {
        form.value.dateStart = format(startOfMonth(thisMonth), formatString)
        form.value.dateEnd = format(endOfMonth(thisMonth), formatString)
      } else if (form.value.period === 'previousMonth') {
        form.value.dateStart = format(startOfMonth(previousMonth), formatString)
        form.value.dateEnd = format(endOfMonth(previousMonth), formatString)
      } else if (form.value.period === 'thisYear') {
        form.value.dateStart = format(startOfMonth(firstMonthOfYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfYear), formatString)
      } else {
        form.value.dateStart = format(startOfMonth(firstMonthOfPreviousYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfPreviousYear), formatString)
      }
    }

    
    const getOrgBranches = async () => {
      const { data } = await $get({ url: `value/getBranchOps`})
      LOV.value.branchLOV = data
    }

    let currentPage  = ref(0)
    let perPage      = ref(50)


    onMounted(async () => {
      handlePeriod()
      getOrgBranches()
      updatePeriodText()

      /*  const params = 
          [
              `dateStart=${form.value.dateStart}`,
              `dateEnd=${form.value.dateEnd}`,
              `branch=${form.value.branch}`,
              `noDocument=${form.value.noDocument}`,
              `angka=${0}`,
          ]
          const total     = await $get({ url: `reports/ch-check-point?${ params.join('&') }`})
          const url = `reports/ch-check-point?${ params.join('&') }`
          await fetchLists(url)
          loading.value = false 
      */
        
    })

    
    const page           = ref(0)
    const totalPageRight = ref(true)
    const totalPageLeft  = ref(true)

    const getDataSearch = async (angkaPage) => {
      loading.value = true
      if(angkaPage == 0){
        page.value = 0
      }else{
        page.value = page.value + angkaPage
      }

      if( page.value < 0){
         page.value = 0
      }

          const params = 
      [
          `dateStart=${form.value.dateStart}`,
          `dateEnd=${form.value.dateEnd}`,
          `branch=${form.value.branch}`,
          `noDocument=${form.value.noDocument}`,
          `angka=${page.value}`,
      ]
      let getTotal     = await $get({ url: `reports/ch-check-point/countDataCh?${ params.join('&') }`})
      getTotal         = parseInt(getTotal.data) - 1
      
      if(getTotal == page.value){
          totalPageRight.value = true
       }else{
          totalPageRight.value = false
       }

       if(page.value <= 0){
          totalPageLeft.value = true
       }else{
          totalPageLeft.value = false
       }

      const url = `reports/ch-check-point?${ params.join('&') }`
      await fetchLists(url)
      loading.value = false
    }

   
    return {
        totalPageLeft,
        totalPageRight,
        getDataSearch,
        loading,
        form,
        LOV,
        isBusy,
        itemLists,
        handlePeriod,
        dateFormat,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        perPageOptions,
        searchQuery,
        isSortDirDesc,
        refListTable,
        statusFilter,
        fetchLists,
        tableColumns,
        periodReportText,
        formatCurrency
    }
  },
  methods: {
    getReport () {
      this.loading = true

      const params = [
                        `dateStart=${this.form.dateStart}`,
                        `dateEnd=${this.form.dateEnd}`,
                        `branch=${this.form.branch}`,
                        `noDocument=${this.form.noDocument}`
                     ]
      const url = `reports/ch-check-point?${ params.join('&') }`
      this.fetchLists(url)
      this.periodReportText = `${this.form.branch} `
      this.loading = false

    },
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            [this.$t('apps.reportCH.singular.report'), '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } }
          ],
          filename: `${this.$t('apps.reportCH.singular.report')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>